<template>
  <section class="mid-cnt">
    <loadingIcon v-if="loading" />
    <div v-else-if="!ads.length" class="tab-cnt prdt-list">
      <div class="noads-data">
        <img src="../assets/images/nodata.svg" alt="" />
        <p>{{$t('Create ad for your Promotion')}}</p>
        <button class="button " @click="$router.push('/create-ad-form?intent=LINK_OPEN&MediaType=1')">
          {{$t('Create Ad')}}
        </button>
      </div>
    </div>
    <div v-else-if="ads.length" class="tab-cnt prdt-list">
      <!-- <div class="totalCount">
        <span>{{ads.length}}</span>
        <p>Total Ads</p>
      </div> -->
      <ul>
        <li
          v-for="(ad, index) in ads"
          :key="index"
          @click.prevent="
            $router.push(`/ad-details?AdNo=${ad.campaignIdentifier}`)
          "
        >
          <span class="img-box">
            <img
              :src="ad.campaignMedia[0].mediaURL"
              v-if="ad.campaignMedia.length"
            />
            <img v-if="ad.leadMedia" :src="ad.leadMedia.mediaURL" alt="" />
          </span>
          <div class="title-blk">
            <h2>
              {{ ad.name }}
            </h2>
            <span class="text-date">{{ extractDate(ad.startDate) }}</span>
            <span
              class="status"
              :class="new Date().getTime() > ad.endDate ? 'expire' : 'active'"
            >
            <!-- {{new Date().getTime() > ad.endDate}}
              {{ new Date().getTime() > ad.endDate ? "Expired" : ad.status === 1 ? "Pending" : "Active" }} -->
              {{new Date().getTime() > ad.endDate ? "Expired" : ad.status === 1
                  ? 'Pending'
                  : ad.status === 2
                  ? 'Approved'
                  : 'Rejected'}}
            </span>
          </div>

          <p>
            {{ ad.description }}
          </p>

          <div class="button-box">
            <!-- <div class="item">
              Selling Price:<strong>{{ ad.sellingPrice }}</strong>
            </div> -->

            <div class="item">
              <!-- <button
                class="button"
                :class="{ disable: new Date().getTime() > ad.endDate }"
              >
                Buy Now
              </button> -->
              <!-- button -->
              <button
                :id="`btn${index}`"
                class="button delete-btn"
                @click.stop="adsDelete(ad.campaignIdentifier, index)"
              >
                {{$t('Delete')}}
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <Toaster :dataValue="toasterData" />
  </section>
</template>

<script>
import {BASE_API_URL} from "../utils/constants";
import {GET} from "../utils/HttpStatusCodeConst"
import ApiRequest from "../services/ApiRequest";
import Toaster from "../components/Toaster";
import loadingIcon from "../components/icons/loadingIcon";
import {SessionHelper} from "../services/Session";

export default {
  name: "AdsListing",
  data() {
    return {
      loading: false,
      toasterData: {},
      disable: false,
      selectedIndex: null,
      ads: []
    };
  },
  components: {
    Toaster,
    loadingIcon
  },
  methods: {
    fetchAdsList(){
      this.loading = true;
      ApiRequest(BASE_API_URL,`/campaign/userAds`, GET).then((data) => {
        if (data.httpStatus === 200) {
          SessionHelper.setCookie("loadWallet", "false");
          this.ads = data.data;
          this.loading = false;
        } else {
          SessionHelper.setCookie("loadWallet", "false");
          this.toasterData = {
            status: true,
            msg: data.message,
            timeoutMs: "5000",
            color: "#000",
          };
           if(data.message === 'Invalid Token'){
             this.toasterData = {
              status: true,
              msg: data.message,
              timeoutMs: "5000",
              color: "#000",
            };
            // SessionHelper.refreshJwtToken()
            this.loading = false;
          }
          if(SessionHelper.getCookie('JWT-TOKEN') !== 'null'){
            this.fetchAdsList();
          }
          this.loading = false;
        }
      }).catch(() => {
        this.toasterData = {
          status: true,
          msg: "Invalid Token",
          timeoutMs: "5000",
          color: "#000",
        };
        this.loading = false;
      });
    },

  adsDelete(identifier, index) {
    document.getElementById(`btn${index}`).classList.add("disable");
    ApiRequest(BASE_API_URL,`/campaign/delete/${identifier}`, "delete").then((data) => {
        if (data.httpStatus) {
          if (data.httpStatus === 200) {
            this.fetchAdsList(index);
          }
          this.toasterData = {
            status: true,
            msg: data.message,
            timeoutMs: "5000",
            color: "#ff6600",
          };
          if(data.message === 'Invalid Token'){
            console.log('=token error==')
            SessionHelper.refereshJwtToken()
            if(SessionHelper.getCookie('JWT-TOKEN') !== 'null'){
              this.adsDelete();
            }
          }
        }
      }); 
    },
    extractDate(timestamp) {
      const month = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return (
        new Date(timestamp).getDate() +
        " " +
        month[new Date(timestamp).getMonth()] +
        " " +
        new Date(timestamp).getFullYear()
      );
    },
  },

  mounted() {
    this.fetchAdsList();
  }
};
</script>
